import { combineReducers, createStore } from "redux";

function checkCookie(name) {
  if (typeof window !== "undefined") {
    // code here
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
}

let personalizationInit = {};

if (checkCookie("shelfgram_token=")) {
  const recentPhotos = checkCookie("recent_photos=");
  personalizationInit = {
    loggedIn: true,
    recentPhotos: recentPhotos && recentPhotos.split(","),
  };
}

const personalization = (state = personalizationInit, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const videoPlayer = (state = { show: false }, action) => {
  switch (action.type) {
    case "HIDE_VIDEO_PLAYER":
      return { show: false };
    case "SHOW_VIDEO_PLAYER":
      return { show: true, ...action.data };
    default:
      return state;
  }
};

const mobileMenu = (state = { show: false }, action) => {
  switch (action.type) {
    case "HIDE_MOBILE_MENU":
      return { show: false };
    case "SHOW_MOBILE_MENU":
      return { show: true };
    default:
      return state;
  }
};

const headerLoaded = (state = false, action) => {
  switch (action.type) {
    case "SET_HEADER_LOADED":
      return action.payload;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  personalization,
  videoPlayer,
  mobileMenu,
  headerLoaded,
});

export default createStore(rootReducer);

export const setHeaderLoaded = (isLoaded) => ({
  type: "SET_HEADER_LOADED",
  payload: isLoaded,
});
