exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-text-search-js": () => import("./../../../src/pages/advanced-text-search.js" /* webpackChunkName: "component---src-pages-advanced-text-search-js" */),
  "component---src-pages-agencies-js": () => import("./../../../src/pages/agencies.js" /* webpackChunkName: "component---src-pages-agencies-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-brokers-js": () => import("./../../../src/pages/brokers.js" /* webpackChunkName: "component---src-pages-brokers-js" */),
  "component---src-pages-campaigns-js": () => import("./../../../src/pages/campaigns.js" /* webpackChunkName: "component---src-pages-campaigns-js" */),
  "component---src-pages-complete-guide-to-ai-heatmaps-js": () => import("./../../../src/pages/complete-guide-to-ai-heatmaps.js" /* webpackChunkName: "component---src-pages-complete-guide-to-ai-heatmaps-js" */),
  "component---src-pages-data-subscriptions-js": () => import("./../../../src/pages/data-subscriptions.js" /* webpackChunkName: "component---src-pages-data-subscriptions-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-for-students-js": () => import("./../../../src/pages/for-students.js" /* webpackChunkName: "component---src-pages-for-students-js" */),
  "component---src-pages-heatmaps-js": () => import("./../../../src/pages/heatmaps.js" /* webpackChunkName: "component---src-pages-heatmaps-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-manufacturers-js": () => import("./../../../src/pages/manufacturers.js" /* webpackChunkName: "component---src-pages-manufacturers-js" */),
  "component---src-pages-mobile-app-js": () => import("./../../../src/pages/mobile-app.js" /* webpackChunkName: "component---src-pages-mobile-app-js" */),
  "component---src-pages-planograms-js": () => import("./../../../src/pages/planograms.js" /* webpackChunkName: "component---src-pages-planograms-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-private-spaces-js": () => import("./../../../src/pages/private-spaces.js" /* webpackChunkName: "component---src-pages-private-spaces-js" */),
  "component---src-pages-product-tour-js": () => import("./../../../src/pages/product-tour.js" /* webpackChunkName: "component---src-pages-product-tour-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-retail-circulars-js": () => import("./../../../src/pages/retail-circulars.js" /* webpackChunkName: "component---src-pages-retail-circulars-js" */),
  "component---src-pages-retailers-js": () => import("./../../../src/pages/retailers.js" /* webpackChunkName: "component---src-pages-retailers-js" */),
  "component---src-pages-social-impact-js": () => import("./../../../src/pages/social-impact.js" /* webpackChunkName: "component---src-pages-social-impact-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-time-travel-store-checks-js": () => import("./../../../src/pages/time-travel-store-checks.js" /* webpackChunkName: "component---src-pages-time-travel-store-checks-js" */)
}

